/*
 * @Author: Andrzej Sulej
 * @Date:   2021-01-16 17:12:34
 * @Last Modified by:   Andrzej Sulej
 * @Last Modified time: 2021-01-26 19:20:50
 */

import './App.scss';
import React from 'react';
import CardIcon from './icon/CardIcon';
import Card from './Card';
import {shuffle} from 'lodash';
import {message} from 'antd';
import {
  AndroidOutlined,
  AppleOutlined,
  WindowsOutlined,
  GithubOutlined,
  AliwangwangOutlined,
  TwitterOutlined,
  QqOutlined,
  DropboxOutlined,
  AmazonOutlined,
  MediumOutlined,
  FacebookOutlined,
  GoogleOutlined,
} from '@ant-design/icons';

import AmbulanceIcon from './icon/AmbulanceIcon'
import BellIcon from './icon/BellIcon'
import BusIcon from './icon/BusIcon'
import CarIcon from './icon/CarIcon'
import ConversationIcon from './icon/ConversationIcon'
import DogIcon from './icon/DogIcon'
import KeyIcon from './icon/KeyIcon'
import PlaygroundIcon from './icon/PlaygroundIcon'
import TrainIcon from './icon/TrainIcon'
import TruckIcon from './icon/TruckIcon'
import WalkIcon from './icon/WalkIcon'
import WeatherIcon from './icon/WeatherIcon'


const puzzles = [
  {answer: "Ambulans",icon: AmbulanceIcon, sound:"/sounds/ambulance.mp3"},
  {answer: "Bell", icon: BellIcon, sound:"/sounds/bell.mp3"},
  {answer: "Bus", icon: BusIcon, sound:"/sounds/bus.mp3"},

  {answer: "Car", icon: CarIcon, sound:"/sounds/car.mp3"},
  {answer: "Construction-work", icon: TruckIcon, sound:"/sounds/constructionwork.mp3"},
  {answer: "Conversation", icon: ConversationIcon, sound:"/sounds/conversation.mp3"},

  {answer: "Dog", icon: DogIcon, sound:"/sounds/dog.mp3"},
  {answer: "Key", icon: KeyIcon, sound:"/sounds/key.mp3"},
  {answer: "Playground", icon: PlaygroundIcon, sound:"/sounds/playground.mp3"},

  {answer: "Traffic-lights", icon: WalkIcon, sound:"/sounds/trafficlights.mp3"},
  {answer: "Train", icon: TrainIcon, sound:"/sounds/train.mp3"},
  {answer: "Weather", icon: WeatherIcon,  sound:"/sounds/weather.mp3"}
]

class App extends React.Component {
  constructor(props){
    super(props);
    this.state={
      puzzles: shuffle(puzzles),
      round: 1
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    message.info(`New round #${this.state.round}`,1);
  }

  handleReset=()=>{
    this.setState((state,props)=>({
      puzzles: shuffle(state.puzzles),
      round: state.round+1
    }))
  }

  render(){
    const {puzzles,round} = this.state;

    return(
      <>
        <div className="honeycomb">
          {/* <div style={{width: '100vw'}}>
            <CardIcon 
              title={"Round #"+round}
              style={{
                width: '31.5vw'
              }}
              fill="#66bb6a"
            />
          </div> */}
          {puzzles.map(item=>(
            <Card key={[round,item.answer].join('-')} puzzle={item} />
          ))}
          {/* <div class="honeycomb-cell honeycomb__placeholder">
            <div class="honeycomb-cell__title">Nowa<small>gra</small></div>
          </div> */}
          {/* <CardIcon 
            className="honeycomb-cell honeycomb__placeholder"
            title="New game"
            onClick={this.handleReset}
            style={{
              width: '31.5vw'
            }}
            fill="#66bb6a"
          /> */}
        </div>
      </>
    )
  }
}

export default App;
