/*
 * @Author: Andrzej Sulej
 * @Date:   2021-01-16 17:13:14
 * @Last Modified by:   Andrzej Sulej
 * @Last Modified time: 2021-01-16 17:13:14
 */
import React from 'react';
import {render} from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
