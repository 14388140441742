/*
 * @Author: Andrzej Sulej
 * @Date:   2021-01-16 17:13:28
 * @Last Modified by:   Andrzej Sulej
 * @Last Modified time: 2021-01-16 17:13:28
 */
import React, {useState,useEffect} from 'react';
import {PlayCircleOutlined, PauseCircleOutlined} from '@ant-design/icons';

const useAudio = (url,mode) => {
  const [audio] = useState(new Audio());
  const [playing,setPlaying] = useState(false);
  
  const toggle =()=>{
    setPlaying(!playing);
  }
  
  audio.src = url;


  useEffect(()=>{
      // console.log('MODE',playing,mode);
      mode!=='run' && setPlaying(false);
    },
    [mode]
  );

  useEffect(()=>{
      playing ? audio.play() : audio.pause();
    },
    [playing, audio]
  );

  useEffect(()=>{
    audio.addEventListener('ended',()=>setPlaying(false));
    return ()=>{
      audio.pause()
      audio.removeEventListener('ended', ()=>setPlaying(false));
    }
  })

  return [playing,toggle ]
}

const Player = ({url, mode, handleClick, style}) => {
  // console.log('AudioPlayer');
  const [playing,toggle] = useAudio(url,mode);

  const handleOnClick=()=>{
    toggle();
    handleClick();
  }

  return (
    <>
      {playing 
        ? <PauseCircleOutlined 
            onClick={handleOnClick}
            style={style}
          /> 
        : <PlayCircleOutlined 
            onClick={handleOnClick}
            style={style}
          />
      }
    </>
  )
}

export default Player