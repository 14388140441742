/*
 * @Author: Andrzej Sulej
 * @Date:   2021-01-16 17:13:31
 * @Last Modified by:   Andrzej Sulej
 * @Last Modified time: 2021-01-23 19:32:53
 */
import React from 'react';
import Icon from '@ant-design/icons';
import  { ReactComponent as TrainIcon } from './svg/train.svg';

// const AmbulanceIcon = (props) =>{
//   console.log(AmbulanceSvg)
//   const ambulanceSvg = () => AmbulanceSvg

//   // return (<Icon component={AmbulanceSvg} {...props} />)
//   return AmbulanceSvg
// }

export default TrainIcon