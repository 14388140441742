/** 
 * @Author: Andrzej Sulej
 * @Date:   2021-01-16 17:13:20
 * @Last Modified by:   Andrzej Sulej
 * @Last Modified time: 2021-01-26 20:17:06
 */
import React, {useState,useEffect} from 'react';
import CardIcon from './icon/CardIcon';
import AudioPlayer from './Player';
import {message} from 'antd';
import {StopOutlined,CheckCircleOutlined} from '@ant-design/icons';

const Card = (props) => {
  const [mode,setMode] = useState("ready");
  const [IconAnswer] = useState(props.puzzle.icon);

  useEffect(()=>{
    if (mode==="solve") {
      message.success(`Congratulations, It's ${props.puzzle.answer} !!!`,2);
    }
  },[mode,props.puzzle.answer])

  const baseStyle={
    position: 'absolute',
    fontSize: 'calc(16px + 1.4em)'
  }

  const posAudio = {
    ready: {
      left: 'calc(50% - (16px + 0.55em)/2)',
      top: 'calc(50% - (16px + 0.55em)/2)'
    },
    run: {
      left: 'calc(50% - (16px + 0.55em)/2)',
      top: 'calc(8% - (16px + 0.55em)/2)'
    }
  }

  return (
    <div className={mode==="run" ? "honeycomb-cell honeycomb-cell__run" : "honeycomb-cell"} >

        {/* <img className="honeycomb-cell__image" src="https://source.unsplash.com/random/2"/> */}
        {/* <div className="honeycomb-cell__title">Impermeables</div> */}
      {/* <CardIcon 
        style={{
          width: '100%',
          // background:'gray'
        }}
        fill={mode==="run" ? "#fff176" : "#fff9c4"}
        mode={mode}
        title={props.puzzle.answer}
      ></CardIcon> */}

      { mode!=="solve" &&
        <AudioPlayer 
          url={props.puzzle.sound} 
          mode={mode} 
          handleClick={()=>setMode("run")}
          style ={{
            ...baseStyle, 
            ...posAudio[mode],
            color: '#795548'
          }}
        />
      }

      { mode==="run" &&
        [   
          <StopOutlined 
            key="cancel" 
            onClick={()=>setMode("ready")}
            style={{
              ...baseStyle, 
              left: 'calc(25% - (16px + 0.55em)/2)',
              top: 'calc(75% - (16px + 0.55em)/2)',
              color: '#C34A4B'
            }}
          />,
          <CheckCircleOutlined 
            key="ok"  
            onClick={()=>setMode("solve")}
            style={{
              ...baseStyle, 
              left: 'calc(75% - (16px + 0.55em)/2)',
              top: 'calc(75% - (16px + 0.55em)/2)',
              color: '#33948b'
            }}
          />
        ]
      }

      { mode==="solve" && 
        <IconAnswer 
        fill="black"
        style ={{
          position: 'absolute',
          fontSize:  '5em', //'calc(16px + 2.55em)',
          left: 'calc(50% - (1em)/2)',
          top: 'calc(50% - (1em)/2)',
          // color:'#80deea'
        }}
        />
      }

      {/* {mode} */}
    </div>
  )
}

export default Card